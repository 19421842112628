module.exports = {
  siteTitle: 'Aleksandar Portfolio', // <title>
  manifestName: 'Aleksandar Portfolio',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: ``, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Aleksandar Drobnjak',
  subHeading: 'Web Designer',
  // social
  socialLinks: [
    {
      style: 'brands',
      icon: 'fa-behance',
      name: 'Behance',
      url: 'https://behance.net',
    },
    {
      style: 'brands',
      icon: 'fa-linkedin',
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/aleksandar-drobnjak-b4878b108/',
    },
    {
      style: 'brands',
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://www.instagram.com/drobnjak.aleksandar/',
    },
  ],
};
